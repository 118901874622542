
const $ = window.jQuery;


/**
 * Start Alpine
 */
window.Alpine = window.AlpineBoot;
Alpine.plugin(window.AlpineFocus);
Alpine.start();

$(function() {

    /**
     * Start Pusher
     */
    //window.Pusher = Pusher;
    Echo = new window.Echo({
        broadcaster: 'pusher',
        key: window.PUSHER_APP_KEY,
        cluster: 'test',
        wsHost: window.CLIENT_PUSHER_HOST,
        wssHost: window.CLIENT_PUSHER_HOST,
        wsPort: window.CLIENT_PUSHER_PORT,
        wssPort: window.CLIENT_PUSHER_PORT,
        encrypted: false,
        forceTLS: false,
        enabledTransports: ['ws'],
    });
    

    
    let userId = window.userId;
    Echo
    .private(`orders.${userId}`)

    /**
     * Listen for completed orders
     */
    .listen('.order_completed', (e) => {

        // notify order detail component to load next order in queue
        Livewire.emit('order_completed', e.orderCode);
    })

    /**
     * Listen for newly added orders.
     */
    .listen('.order_added', (e) => {

        // notify order detail component to load next order in queue
        Livewire.emit('order_added', e.orderCode);
    });

});
  